import React, { useState, useContext, useEffect } from 'react';
// import uparrow from '../../Assets/uparrow.svg';
import downarrow from '../../Assets/downarrow.svg';
import claimstatus from '../../Assets/claim-status.svg';
// import ClaimLettersImg from '../../Assets/ClaimLettersImg.svg';
import ClaimLettersImg from '../../Assets/claimLettersPng.png';

import greentick from '../../Assets/green-tick.svg';
import pending from '../../Assets/pending.svg';
import DocumentUpload from '../DocumentUpload/DocumentUpload';
import Skeleton from 'react-loading-skeleton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ClaimContext } from '../../context/ClaimContext';
import Loader from '../Loader/Loader';
import Insertphoto from '../../Assets/Insertphoto.svg';
import pdfImg from '../../Assets/Icon/PDF.svg';
import viewImg from '../../Assets/Icon/On.svg';
import downloadImg from '../../Assets/Icon/Download.svg';
import infoImg from '../../Assets/Icon/Info.svg';
import { env } from '../../config/env';
import ReactGA from 'react-ga';



const ClaimLetters = props => {
  const context = useContext(ClaimContext);
  const [loading, setLoading] = useState(false);
  const [clicked_index, setClickedIndex] = useState(null);
  let [clLetters, setClLetters] = useState([]);



  const documentDownload = (docid, doc_name, i) => {
    setClickedIndex(i);
    setLoading(true);
    const requestOptions = {
      method: 'GET',
      headers: {
        // 'content-type': 'application/json',
        token: window.sessionStorage.getItem('_ssid'),
      },
    };

    fetch(`${env.SERVER_URL}/download_document/${docid}`, requestOptions)
      .then(res => {
        return res.blob();
      })
      .then(data => {
        if(data.type =='application/json'){
          data = new Blob([data], { type: "application/pdf" });
        }
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(data);
        a.download = doc_name;
        a.click();
        setLoading(false);
        setClickedIndex(null);
        let mobile = localStorage.getItem('mobile');
        ReactGA.event({
          category: 'Claim_letter_download',
          action: 'Claim_letter_download',
          label:'Claim_letter_download'
        });
      });
  };


  function clLetterFromDoc() {
    let clLettersArray = [];
    if (context?.documents?.length > 0) {
      context.documents.map(function (element, index) {
        if (element['a:DOCTYPE_TXT']._text === 'Letter') {
          clLettersArray.push(element);
        }
      });
      setClLetters(clLettersArray);
    }
  }

  useEffect(() => {
    clLetterFromDoc();
  }, [])


  if (context) {
    return (
      <>
        <Accordion className='accordianContainer'>
          <AccordionSummary
            aria-controls="panel1a-content"
            expandIcon={<img src={downarrow} alt="" />}
            id="panel1a-header"
          >
            <div className="document-status-sub">
              <img src={ClaimLettersImg} alt="" />
              <p className="document-status-text">
                <span className="heading document_status">
                  Claim Letters
                  {/* {context.data.required_documents ? (
                    <img
                      className="pending_status"
                      src={pending}
                      alt="pending"
                    />
                  ) : (
                    <></>
                  )} */}
                </span>
                Track your Letters
              </p>
            </div>
          </AccordionSummary>
          <hr />
          <AccordionDetails>
            <div>
              {/* {context.data.required_documents ? (
                <div className="documents_required_Section">
                  <h5>Documents Required</h5>
                  <p style={{ fontSize: 14, fontWeight: 500, lineHeight: 1.7 }}>
                    {context.data.required_documents}
                  </p>
                </div>
              ) : (
                <div></div>
              )} */}
              <div className="DocumentUploadPart">
                {clLetters?.length>0 ? (
                  clLetters.map(function (element, index) {
                    let a = 0;
                    if (element['a:DOCTYPE_TXT']._text === 'Letter') {
                      // console.log("aaaa", a, index)
                        a++
                        // console.log("aaaa", a, index)

                      return (
                        // ++++++++++++++++

                        <div className="documentVisibleSection" key={index}>
                          <div className="documentVisibleSectionLs">
                            <img
                              src={
                                element['a:STOREDTYPE_TXT']._text ==
                                'application/pdf'
                                  ? pdfImg
                                  : Insertphoto
                              }
                              className="DocumentUploadPartImg"
                            />

                            <div className="details_copntainer_documents">
                              <span className="document_type">
                                {element['a:DOCTYPE_TXT']._text.substr(0, 20)}
                              </span>
                              <br />
                              <span className="document_date">
                                {element['a:CREATED_DATE']._text}
                              </span>
                            </div>
                          </div>

                          <div className="documentVisibleSectionRs">
                            {/* <div className="DocumentUploadPartImgDiv">
                             <img src={viewImg} /> 
                          </div>  */}
                            <div></div>
                            {loading && clicked_index === index ? (
                              <Loader balls_color="grey" />
                            ) : (
                              <div
                                className="DocumentUploadPartImgDiv"
                                onClick={() =>
                                  documentDownload(
                                    element['a:DOCID_NUM']._text,
                                    element['a:DOCTYPE_TXT']._text,
                                    index
                                  )
                                }
                                                              >
                                <img src={downloadImg} />
                              </div>
                            )}
                          </div>
                        </div>
                        // ======================================================
                        // <span className="small-div" key={i}>
                        //   <img src={greentick} alt="" />
                        //   <span style={{lineHeight:"1.7",padding:"6px 0px"}}>{element['a:DOCTYPE_TXT']._text}</span>
                        //   <span style={{lineHeight:"1.7",padding:"6px 0px"}}>{element['a:CREATED_DATE']._text}</span>
                        // </span>
                      );
                    }
                    
                    // else if(index == (context.documents.length) && (a == 0)) {
                    //   return (
                    //     <p
                    //       style={{
                    //         textAlign: 'center',
                    //         fontSize: '12px',
                    //         color:'rgba(0, 0, 0, 0.87)'
                    //       }}
                    //     >
                    //       No claim letter yet
                    //     </p>
                    //   );
                    // }
                  })
                ) : (
                  <p className='noclaimletters'>No claim letter yet</p>
                )}
              </div>
              {/* ================================================================ select accordian*/}
            </div>
          </AccordionDetails>
        </Accordion>
      </>
    );
  } else if (props.documentsError) {
    return <></>;
  } else {
    return (
      <div className="claim-details">
        <div className="claim-details-sub">
          <Skeleton width="3rem" height="3rem" circle={false} count={1} />
          <p className="claim-details-text">
            <span className="heading">
              <Skeleton width="8rem" height="1rem" circle={false} count={1} />
            </span>
            <br />
            <Skeleton width="8rem" height="0.4rem" circle={false} count={1} />
          </p>
        </div>
        <div className="float-right">
          <Skeleton width="0.8rem" height="0.8rem" circle={false} count={1} />
        </div>
      </div>
    );
  }
};

export default ClaimLetters;

{
  /* <section className="document-status-main">
          <div className="document-status" onClick={() => setShow(!show)}>
            <div className="document-status-sub">
              <img src={claimstatus} alt="" />
              <p className="document-status-text">
                <span className="heading">Document Status</span> <br />
                Track your document status
              </p>
            </div>
            <div className="float-right">
              {show ? (
                <img src={uparrow} alt="" />
              ) : (
                <img src={downarrow} alt="" />
              )}
            </div>
          </div>
          <div>
            <div
              className={
                show ? "document-status-inner" : "document-status-hide"
              }
            >
              <hr />
              <div>
                {context.documents.map(function (element, i) {
                  return (
                    <span className="small-div" key={i}>
                      <img src={greentick} alt="" />
                      <p>{element["a:DOCUMENTNAME_TXT"]._text}</p>
                    </span>
                  );
                })}
                <span>
                  <DocumentUpload />
                </span>
              </div>
            </div>
          </div>
        </section> */
}
